import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import LoadingScreen from "../components/LoadingScreen";
import axios from "../utilities/axios";
import transformCookiesToObject from "../utilities/transformCookiesToObject";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (token) => {
  if (!token) {
    return false;
  }

  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (token) => {
  if (token) {
    //cookies
    document.cookie = `token=${token}`;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    var date = new Date();
    date.setTime(date.getTime() + 5 * 24 * 60 * 60 * 1000);
    document.cookie = "token" + "=" + token + "; expires=" + date.toGMTString();

    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "FORGOT": {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    case "RESET": {
      return {
        ...state,
      };
    }
    case "CONFIRM": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "EDIT_PASSWORD": {
      return {
        ...state,
        isAuthenticated: true,
      };
    }
    case "GOOGLE": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  forgotPassword: () => Promise.resolve(),
  confirmPassword: () => Promise.resolve(),
  editPassword: () => Promise.resolve(),

  loginWithGoogle: () => Promise.resolve(),
  deleteAllCookies: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email, password) => {
    const response = await axios.post(`/auth/login`, {
      email,
      password,
    });
    const { token, user } = response.data;
    setSession(token);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };
  const forgotPassword = async (email) => {
    const response = await axios.post(`/auth/forgot`, {
      email,
    });

    dispatch({
      type: "FORGOT",
    });
    return response;
  };
  const resetPassword = async (code) => {
    const response = await axios.post(`/auth/verify-code`, {
      code,
    });
    dispatch({
      type: "RESET",
    });

    return response;
  };
  const confirmPassword = async (code, password) => {
    const response = await axios.patch(
      `/auth/reset/${code}`,

      {
        password,
      }
    );
    const { token, user } = response.data;
    setSession(token);
    dispatch({
      type: "CONFIRM",
      payload: {
        user,
      },
    });

    return response;
  };

  const editPassword = async (passwordCurrent, password, passwordConfirm) => {
    const response = await axios.patch(`/auth/editMyPassword`, {
      passwordCurrent,
      password,
      passwordConfirm,
    });

    dispatch({
      type: "EDIT_PASSWORD",
    });

    return response;
  };
  const loginWithGoogle = async (idToken) => {
    const response = await axios.post(`/auth/google`, {
      idToken,
    });

    const { token, user } = response.data;
    setSession(token);
    dispatch({
      type: "GOOGLE",
      payload: {
        user,
      },
    });

    return response;
  };

  useEffect(() => {
    const cookies = transformCookiesToObject();

    const initialise = async () => {
      try {
        const token = cookies.token;
        if (token && isValidToken(token)) {
          setSession(token);
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        forgotPassword,
        resetPassword,
        confirmPassword,
        loginWithGoogle,
        editPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
